<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
        <el-form-item prop="mobile" label="账号">
            <el-input placeholder="请输入手机号" v-model="ruleForm.mobile" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="昵称">
            <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item prop="passwd" label="初始密码">
            <el-input placeholder="请输入密码" v-model="ruleForm.passwd" show-password></el-input>
        </el-form-item>
        <el-form-item prop="passwd" label="角色">
            <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item,idx) in selectList" :key="idx" :label="item.id">
                    {{item.name}}
                </el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item>
            <el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    import { IMG_URL_PRE } from '@/config';
    import { uploadFile } from '@/service/common';
    import { getRoles,addUser } from '@/service/purview';
    export default {
        props: ['form'],
        data() {
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入手机号'));
                } else {
                    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            return {
                ruleForm: {
                    mobile:"",
                    name: '',
                    passwd:"",
                    role_ids:"",
                },
                rules: {
                    mobile: [{validator: checkPhone, trigger: 'blur'}],
                    passwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                },
                checkList:[],
                selectList:[],
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    if (rst && rst.length>0) {
                        this.selectList = rst;
                    }
                    console.log(this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.submit();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submit() {
                this.ruleForm.role_ids = this.checkList;
                addUser(this.ruleForm).then(rst => {
                    this.$emit("refreshItems");
                    this.$emit("hide");
                    this.$message.success("添加成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
.comp-cert-uploader >>> .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 178px;
        height: 178px;
    }

    .comp-cert-uploader >>> .el-upload:hover {
        border-color: #409EFF;
    }

    .comp-cert-logo-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .comp-cert-logo {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>