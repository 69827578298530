<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
        <el-form-item prop="mobile" label="账号">
            <el-input placeholder="请输入手机号" v-model="ruleForm.mobile" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="姓名">
            <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色">
            <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item,idx) in selectList" :key="idx" :label="item.id">
                    {{item.name}}
                </el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item prop="gender" label="性别">
            <el-radio-group v-model="ruleForm.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="头像">
            <el-upload class="comp-cert-uploader" action="xx" :show-file-list="false"
                    :accept="uploadAccept" :http-request="uploadLegal" :before-upload="beforeUpload">
                <img v-if="ruleForm.avatar" :src="imgUrl+ruleForm.avatar" class="comp-cert-logo">
                <i v-else class="el-icon-plus comp-cert-logo-uploader-icon"></i>
            </el-upload>
        </el-form-item>
        <el-form-item prop="intro" label="个人介绍">
            <el-input type="textarea" v-model="ruleForm.intro"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机">
            <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item prop="tel" label="座机">
            <el-input v-model="ruleForm.tel"></el-input>
        </el-form-item>
        <el-form-item prop="mail" label="邮箱">
            <el-input v-model="ruleForm.mail"></el-input>
        </el-form-item>
        <el-form-item prop="wechat" label="微信">
            <el-input v-model="ruleForm.wechat"></el-input>
        </el-form-item>
        <el-form-item prop="qq" label="QQ">
            <el-input v-model="ruleForm.qq"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    import { IMG_URL_PRE } from '@/config';
    import { uploadFile } from '@/service/common';
    import { getRoles,setUser } from '@/service/purview';
    export default {
        props: ['form'],
        data() {
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入手机号'));
                } else {
                    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            return {
                imgUrl: IMG_URL_PRE,
                uploadAccept: 'image/jpeg,image/gif,image/png',
                ruleForm: "",
                rules: {
                    mobile: [{required: true,validator: checkPhone, trigger: 'blur'}],
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                },
                checkList:[],
                selectList:[],
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                    this.checkList = this.form.roles;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            this.checkList = this.form.roles;
            // console.log(this.form);
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    if (rst && rst.length>0) {
                        this.selectList = rst;
                    }
                    console.log(this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            uploadLegal(item){
                uploadFile(item.file).then(url=>{
                    // this.ruleForm.avatar=url;
                    this.$set(this.ruleForm,'avatar',url)
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            beforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传LOGO图片大小不能超过 10MB!');
                }
                return isLt2M;
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.submit();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submit() {
                this.ruleForm.role_ids = this.checkList;
                setUser(this.ruleForm).then(rst => {
                    this.$emit("refreshItems");
                    this.$emit("hide");
                    this.$message.success("修改成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        }
    }
</script>
<style scoped>
.comp-cert-uploader >>> .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 178px;
        height: 178px;
    }

    .comp-cert-uploader >>> .el-upload:hover {
        border-color: #409EFF;
    }

    .comp-cert-logo-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .comp-cert-logo {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>