<template>
  <div style="height: 100%">
    <div class="page-header">管理管理员</div>
    <div style="margin: 20px; text-align: right">
      <el-button @click="add()" size="small" type="primary"
        >新建管理员</el-button
      >
    </div>
    <div class="block" style="height: calc(100% - 159px)">
      <div class="list" style="height: calc(100% - 60px); overflow-y: auto">
        <el-table :data="list" style="width: 100%">
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="mobile" label="账号" width="120">
          </el-table-column>
          <el-table-column prop="name" label="昵称"> </el-table-column>
          <el-table-column prop="roles" label="角色">
            <template slot-scope="scope">
              <span
                v-for="(item, idx) in scope.row.roles"
                :key="idx"
                style="margin-right: 10px"
              >
                <span style="font-size: 13px">{{ item.name }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row)">
                <span class="icon iconfont icon-bianji1"></span>&nbsp;修改
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="editPasswd(scope.row)"
              >
                修改密码
              </el-button>
              <el-button type="text" size="small" @click="del(scope.row)">
                <span class="icon iconfont icon-shanchu"></span>&nbsp;删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding: 12px">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="queryOpt.pageNumber"
          :page-size="queryOpt.pageCount"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <AddManager
        v-if="popTitle == '新建管理员'"
        :form="ruleForm"
        @refreshItems="refreshItems"
        @hide="popVisible = false"
      ></AddManager>
      <EditManager
        v-if="popTitle == '修改管理员信息'"
        :form="ruleForm"
        @refreshItems="refreshItems"
        @hide="popVisible = false"
      ></EditManager>
      <EditPasswd
        v-if="popTitle == '修改管理员密码'"
        :form="ruleForm"
        @refreshItems="refreshItems"
        @hide="popVisible = false"
      ></EditPasswd>
    </el-dialog>
  </div>
</template>
<script>
import { getUsers, delUser } from "@/service/purview";
import AddManager from "./AddManager.vue";
import EditManager from "./EditManager.vue";
import EditPasswd from "./EditPasswd.vue";
export default {
  components: {
    AddManager,
    EditManager,
    EditPasswd,
  },
  data() {
    return {
      popVisible: false,
      popTitle: "",
      queryOpt: {
        pageNumber: 1,
        pageCount: 15,
      },
      total: 0,
      list: [],
      ruleForm: {
        mobile: "",
        passwd: "",
        name: "",
        gender: 0,
        intro: "",
        phone: "",
        tel: "",
        mail: "",
        wechat: "",
        qq: "",
        role_ids: "",
      },
    };
  },
  mounted() {},
  created() {
    this.refreshItems();
  },
  methods: {
    refreshItems() {
      console.log("refreshItems");
      getUsers()
        .then((rst) => {
          if (rst && rst.length > 0) {
            this.list = rst;
          }
          console.log(this.list);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    add() {
      this.popTitle = "新建管理员";
      this.popVisible = true;
      this.ruleForm = {
        mobile: "",
        passwd: "",
        name: "",
        gender: 0,
        intro: "",
        phone: "",
        tel: "",
        mail: "",
        wechat: "",
        qq: "",
        role_ids: "",
      };
    },
    edit(item) {
      this.popTitle = "修改管理员信息";
      this.popVisible = true;
      this.ruleForm = item;
      this.ruleForm = {
        user_id: item.id,
        avatar: item.avatar,
        mobile: item.mobile,
        name: item.name,
        gender: item.gender,
        intro: item.intro,
        phone: item.phone,
        tel: item.tel,
        mail: item.mail,
        wechat: item.wechat,
        qq: item.qq,
      };
      if (
        item.roles &&
        JSON.stringify(item.roles) != "{}" &&
        item.roles.length > 0
      ) {
        this.ruleForm.roles = item.roles.reduce((pre, cur) => {
          pre.push(cur.id);
          return pre;
        }, []);
      }
      console.log(this.ruleForm);
    },
    editPasswd(item) {
      this.popTitle = "修改管理员密码";
      this.popVisible = true;
      this.ruleForm = {
        user_id: item.id,
        passwd: "",
      };
      console.log(this.ruleForm);
    },
    del(item) {
      this.$confirm(`确认要删除吗?`, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delUser({ user_id: item.id })
          .then((rst) => {
            this.refreshItems();
            this.$message.success("删除成功!");
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      });
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.icon {
  font-size: 13px;
}
</style>
