<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
        <el-form-item prop="passwd" label="密码">
            <el-input placeholder="请输入密码" v-model="ruleForm.passwd" show-password></el-input>
        </el-form-item>
        <el-form-item>
            <el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    import { IMG_URL_PRE } from '@/config';
    import { uploadFile } from '@/service/common';
    import { setUserPasswd } from '@/service/purview';
    export default {
        props: ['form'],
        data() {
            
            return {
                ruleForm: {
                    user_id:"",
                    passwd:"",
                },
                rules: {
                    passwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ],
                },
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            console.log(this.form);
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.submit();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submit() {
                setUserPasswd(this.ruleForm).then(rst => {
                    this.$emit("refreshItems");
                    this.$emit("hide");
                    this.$message.success("修改成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
.comp-cert-uploader >>> .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 178px;
        height: 178px;
    }

    .comp-cert-uploader >>> .el-upload:hover {
        border-color: #409EFF;
    }

    .comp-cert-logo-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .comp-cert-logo {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>